<template>
	<div class="flex flex-row flex-grow w-full" v-if="authUser">
		<Menu>
			<template v-slot:li>
				<li v-for="(item, key) in menuList" :key="'A' + key">
					<div class="flex flex-row h-14 mr-auto mb-3 ml-5 items-center text-sm cursor-pointer" :class="{ active: tabIsActive(item, menu) }" @click.prevent="goRoute(item)">
						<div class="flex justify-center items-center h-8 min-h-8 w-8 min-w-8">
							<Icons :icon-name="item.icon" :title="!menu ? item.name : ''" />
						</div>
						<p class="flex p-2" v-if="menu">{{ item.name }}</p>
					</div>
					<ul v-if="item.subItems && menu">
						<li
							v-for="(subitem, subKey) in item.subItems"
							:key="'sub' + subKey"
							class="flex pl-8 flex-row h-14 mr-auto mb-6 ml-5 items-center text-sm cursor-pointer"
							:class="{ active: $route.path == subitem.router }"
							@click.prevent="goRoute(subitem)"
						>
							<p class="flex p-2 relative" v-if="menu">{{ subitem.name }}</p>
						</li>
					</ul>
				</li>
			</template>
		</Menu>
		<div class="w-full w-11/12">
			<Header></Header>
			<router-view />
		</div>
	</div>

	<router-view v-else />
</template>

<script>
import Menu from '../../components/Menu.vue';
import Header from '../../components/Header.vue';
import Icons from '../../components/Icons.vue';

// Глобальные данные стора
import { getters, actions, methods } from '../../store/store';

export default {
	name: 'OsMember',
	components: {
		Menu,
		Header,
		Icons
	},
	computed: {
		...getters,
		calculateActiveTab() {
			switch (this.$route.name) {
				case 'General':
					return 0;
				case 'FedMedOrg':
					return 1;
				case 'MedicalOrganizations':
					return 2;
				case 'nocResultsCurrentYears':
					return 3;
				case 'nocResultsArchive':
					return 4;
				case 'PatientReviews':
					return 5;
				case 'dinamicalFilling':
					return 8;
				case 'AdministrationUser':
					return 10;
				case 'Guide':
					return 12;
				default:
					return false;
			}
		}
	},
	data: () => ({
		menuList: [
			{
				name: 'Субъекты РФ',
				router: '/minzdrav/general',
				icon: 'home'
			},
			{
				name: 'Федеральные медицинские организации',
				router: 'fedmedorg',
				icon: 'fed-med-org'
			},
			{
				name: 'Медицинские организации',
				router: '/minzdrav/medical-organizations',
				icon: 'pluse'
			},
			{
				name: 'Результаты НОК текущего года',
				router: '/minzdrav/noc-results-current-years',
				icon: 'result-nok'
			},
			{
				name: 'Архив результатов НОК',
				router: '/minzdrav/noc-results-archive',
				icon: 'driver'
			},
			{
				name: 'Отзывы пациентов',
				router: '/minzdrav/patient-reviews',
				icon: 'message'
			},
			{
				name: 'Аналитические отчеты',
				router: '/minzdrav/analytical-reports',
				icon: 'diagram',
				subItems: [
					{
						name: 'Универсальный отчет по МО',
						router: '/minzdrav/report-constructor'
					},
					{
						name: 'Динамика заполнения анкет',
						router: '/minzdrav/dinamical-filling'
					}
				]
			},
			{
				name: 'Администрирование',
				router: '/minzdrav/administration',
				icon: 'setting',
				subItems: [
					{
						name: 'Пользователи Системы',
						router: '/minzdrav/administration-user'
					},
					{
						name: 'Роли Системы',
						router: '/minzdrav/administration-roles'
					},
					{
						name: 'Конструктор анкет',
						router: '/minzdrav/form-constructor'
					}
				]
			},
			{
				name: 'Справочники Системы',
				router: '/minzdrav/guide',
				icon: 'dictionary'
			},
			{
				name: 'Активность пользователей',
				router: '/minzdrav/user-activity',
				icon: 'users'
			}
		]
	}),
	methods: {
		...actions,
		...methods,
		goRoute(item) {
			if (item.router === 'fedmedorg') {
				let routeData = this.$router.resolve({ path: '/ogv/general', query: { regionId: '022b0e2d-5b3d-4ccd-8737-fd05b7967d3f' } });
				window.open(routeData.href, '_blank');
				return;
			}

			if (item.subItems !== undefined && item.subItems.length > 0) {
				item = item.subItems[0];
			}

			if (item.router !== this.$route.path) {
				this.$router.push({ path: item.router, query: this.$route.query });
			}
		},
		tabIsActive(item, menu) {
			if (item.router === this.$route.path) {
				return true;
			}

			if (!menu && item.subItems !== undefined) {
				for (let i = 0; i < item.subItems.length; i += 1) {
					if (item.subItems[i].router === this.$route.path) {
						return true;
					}
				}
			}

			return false;
		}
	},
	created() {
		this.setCurrentRegion();
	}
};
</script>

<style scoped>
.open {
	color: #3377ff;
}
</style>
